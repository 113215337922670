import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { Hide } from "@rebass/hide";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { breadcrumbs } from "shared/helpers/schema";
import Card from "../../../components/Card";
import Contact from "../../../components/Contact";
import GoogleReviews from "../../../components/GoogleReviews";
import Hero, { Intro } from "../../../components/Hero";
import ImmoweltBadge from "../../../components/ImmoweltBadge";
import Kranicon from "../../../components/Kranicon";
import Page from "../../../components/Page";
import PageBlock from "../../../components/PageBlock";
import PageTitle from "../../../components/PageTitle";
import ProfileImage from "../../../components/ProfileImage";
import { Link } from "gatsby";

const List = styled.ul`
  list-style-type: "✓  ";
  margin-bottom: 0;
`;

const Kontakt = ({ data }) => (
  <Page
    header={
      <Hero fluid={data.linda.fluid} icon={<Kranicon icon="kontakt" />}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={css`
            max-width: 40em;
          `}
        >
          <PageTitle>Jetzt Linda Bäumer kontaktieren</PageTitle>
          <Intro>Ihr Immobilienspezialist für Weimar.</Intro>
          <Flex alignItems="stretch" flexWrap="nowrap">
            <Hide xsmall>
              <ImmoweltBadge />
            </Hide>
            <GoogleReviews />
          </Flex>
        </Flex>
      </Hero>
    }
  >
    <Helmet
      title="Kontakt"
      meta={[
        {
          name: "description",
          content:
            "Immer erreichbar unter 036450 44 99 33, info@immobilien-kranichfeld.de oder das Kontaktformular auf unserer Website. Büro: Große Gebind 22b | 99448 Kranichfeld",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Kontakt",
              link: "/kontakt/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex my={4} flexWrap="wrap" alignItems="stretch">
        <Box width={[1, null, 1 / 2]} p={4}>
          <Card>
            <ProfileImage />
            <p style={{ textAlign: "center" }}>Linda Bäumer</p>
            <h2>Die Immobilienmaklerin für Weimar</h2>
            <p>
              Linda Bäumer ist eine der{" "}
              <Link to="/immobilienmakler-weimar/">
                besten Immobilienmakler für Weimar
              </Link>{" "}
              und mit Ihrem Team seit über 10 Jahren erfolgreich für Ihre Kunden
              unterwegs. Vertrauen Sie auf Ihre Erfahrung und Kompetenz.
            </p>
            <List>
              <li>
                Kompetente und professionelle{" "}
                <strong>Rund­um­be­treu­ung</strong>
              </li>
              <li>
                Detaillierte und <strong>transparente Arbeitsweise</strong>
              </li>
              <li>
                Ehrlicher und <strong>freundlicher Umgang</strong>
              </li>
              <li>
                <strong>Flexible Lösungen</strong> individueller Probleme
              </li>
            </List>
          </Card>
        </Box>
        <Box width={[1, null, 1 / 2]} p={4}>
          <Card red>
            <h2>Schreiben Sie mir ganz unverbindlich</h2>
            <Contact />
          </Card>
        </Box>
      </Flex>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    linda: imageSharp(fluid: { originalName: { regex: "/linda_1/" } }) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Kontakt;
